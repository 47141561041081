import React, { useEffect, useState } from "react";
import Footer from "../../components/Reusable/js/Footer";
import { Toaster } from "react-hot-toast";
import styles from "./css/Homepage.module.css"
import Nav from "../../components/Reusable/js/Nav";
import { LatestNews, Posts } from "../../components/SideComponents/js/LatestNews";
import SiteNews from "../../components/SideComponents/js/SiteNews";
import { HomepageServices } from "../../components/SideComponents/js/HomepageServices";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { firebaseConfig, SmallSpinner } from "../../context/imports";
import { initializeApp } from "firebase/app";
import { collection, getDocs, getFirestore, limit, orderBy, query } from "firebase/firestore";


const Homepage = () => {

  const login = async () => {
    const email = "";
    const password = "";
    try {
      await axios.post(`${process.env.REACT_APP_API}/api/v1/auth/login`, { email, password });
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    login();
  }, [])


  return (
    <>
      <Nav />
      <Toaster />
      <div className="container" style={{ display: "flex", flexDirection: "column" }}>
        <div className={`container ${styles.content}`}>
          <div className={`m-2 ${styles.latestnews}`}>
            <LatestNews />
          </div>
          <div className={`${styles.middle}`}>
            <div className="container" style={{ overflowY: "auto", marginTop: "20px" }}>
              <HomepageServices />
            </div>
          </div>
          <div className={`${styles.sitenews} m-2`} >
            <Posts />
          </div>
        </div>


      </div>
      <hr />
      <AllFeatures />
      <hr />
      <SampleArticles />

      <Footer />
    </>
  );
};

export default Homepage;



export const SampleArticles = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false)

  // Fetch the latest 3 articles from Firestore
  const fetchArticles = async () => {
    setLoading(true)
    try {
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
      const articlesRef = collection(db, '/becstuoweb/navigation/articles');

      // Fetch the latest 3 articles based on their timestamp or some order
      const querySnapshot = await getDocs(
        query(articlesRef, orderBy('date', 'desc'), limit(3))
      );

      const latestArticles = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setArticles(latestArticles); // Update the state with the fetched articles
    } catch (error) {
      console.error('Error fetching articles:', error);
    }
    setLoading(false)
  };

  useEffect(() => {
    fetchArticles(); // Call the function to fetch the articles when the component mounts
  }, []);

  const navigate = useNavigate()

  return (
    <div className={styles.homeContainer}>
      <h1 className={styles.header}>Latest Articles</h1>
      <div className={styles.articleRow}>
        {loading ? (
          <SmallSpinner />
        ) : articles.length > 0 ? (
          articles.map((article) => (
            <div key={article.id} className={styles.articleCard}>
              <h2 className={styles.articleTitle}>{article.title}</h2>
              <p className={styles.articleSnippet}>
                {article.content.slice(0, 150)}...
              </p>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  navigate(`/blog/articles/${article.slug}`);
                }}
              >
                Read More
              </button>
            </div>
          ))
        ) : (
          <p className={styles.noArticles}>No articles available at the moment.</p>
        )}
      </div>
      <button className="btn btn-outline-danger my-3" onClick={()=>{navigate("/blog/articles")}}>View all articles</button>

    </div>
  );
};

export const AllFeatures = () => {
  const features = [
    {
      title: 'Previous Papers',
      description: 'Access past question papers for all semesters.',
      icon: '📝',
      link: '/previous-papers'
    },
    {
      title: 'Placement Guidance',
      description: 'Get tips and resources for acing your placements.',
      icon: '💼',
      link: '/placement-materials'
    },
    {
      title: 'Study Materials',
      description: 'Explore a wide range of study materials and resources.',
      icon: '📚',
      link: '/materials'
    },
    {
      title: 'Semester Results',
      description: 'Check your college semester results online.',
      icon: '📊',
      link: '/results'
    },
    {
      title: 'Alumni Network',
      description: 'Connect with successful alumni from your college.',
      icon: '🌐',
      link: '/alumni'
    },
    {
      title: 'Placement Resources',
      description: 'Access training materials and mock tests for placements.',
      icon: '📂',
      link: '/placement-materials'
    },
    {
      title: 'Mentorship Program',
      description: 'Get guidance from experienced mentors in your field.',
      icon: '👩‍🏫',
      link: '/techies'
    },
    {
      title: 'Placement Achievers',
      description: 'Meet and get inspired by students with successful placements.',
      icon: '🏆',
      link: '/dashboard/user/y20placements'
    },
  ];

  return (
    <>
      <div className="container">
        <h2 className="text-center">Our Features</h2>
        <div className={`${styles.featuresContainer} ${styles.row}`}>
          {features.map((feature, index) => (
            <Link to={feature.link} key={index} className={styles.featureCard}>
              <div className={styles.cardIcon}>{feature.icon}</div>
              <h3 className={styles.cardTitle}>{feature.title}</h3>
              <p className={styles.cardDescription}>{feature.description}</p>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};


export const PreviousPapers = () => {
  const subjectsOverview = [
    {
      department: 'Computer Science',
      subjects: ['Data Structures', 'Algorithms', 'Machine Learning', 'Operating Systems'],
    },
    {
      department: 'Electronics & Communication',
      subjects: ['Digital Signal Processing', 'Microprocessors', 'VLSI Design', 'Control Systems'],
    },
    {
      department: 'Mechanical Engineering',
      subjects: ['Thermodynamics', 'Fluid Mechanics', 'Automobile Engineering', 'CAD/CAM'],
    },
    {
      department: 'Civil Engineering',
      subjects: ['Structural Analysis', 'Geotechnical Engineering', 'Surveying', 'Building Materials'],
    },
    {
      department: 'Honors Subjects',
      subjects: ['Advanced Mathematics', 'Quantum Physics', 'Environmental Engineering'],
    },
  ];

  return (
    <>
      <div className="container">
        <h2 className="text-center">Previous Papers Overview</h2>
        <div className="row">
          {subjectsOverview.map((dept, index) => (
            <div key={index} className="col-md-6 col-lg-4 mb-4">
              <div className="card shadow-sm">
                <div className="card-body">
                  <h5 className="card-title">{dept.department}</h5>
                  <ul>
                    {dept.subjects.map((subject, subIndex) => (
                      <li key={subIndex}>{subject}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="text-center mt-4">
          <button className="btn btn-primary">
            <Link to="/previous-papers" style={{ color: 'white', textDecoration: 'none' }}>
              View All Previous Papers
            </Link>
          </button>
        </div>
      </div>
    </>
  );
};
