// exports.js or index.js

import Nav from '../components/Reusable/js/Nav';
import Footer from '../components/Reusable/js/Footer';
import Spinner, { SmallSpinner } from '../components/Reusable/js/Spinner';
import Articles from './../Features/Articles/Articles';
import SingleArticle from '../Features/Articles/SingleArticle';


const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID
};

export {
  Nav,
  Footer,
  Spinner,
  SmallSpinner,
  firebaseConfig,
  Articles,
  SingleArticle
};
