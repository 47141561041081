import React, { useEffect, useState } from 'react'
import styles from "./Placements.module.css"
import Nav from '../../components/Reusable/js/Nav';
import Footer from "../../components/Reusable/js/Footer"
import { NavLink } from 'react-router-dom';
import { r20links } from '../../Data/SiteData';
import { firebaseConfig, SmallSpinner, Spinner } from '../../context/imports';
import { initializeApp } from 'firebase/app';
import { collection, getDocs, getFirestore } from 'firebase/firestore';

const PlacementOffers = () => {

  const [loading, setLoading] = useState(false)


  const [display, setDisplay] = useState("")

  const Defaulter = () => {
    return (
      <>

      </>
    )
  }

  const Breadcrumb = () => {
    return (
      <nav aria-label="breadcrumb" style={{ margin: "0", padding: "0" }}>
        <ol className="breadcrumb m-2">
          {/* <li className="mr-2" style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
            <abbr title="Go back">
              <IoArrowBackCircle style={{ fontSize: '1.8rem' }} />
            </abbr>
          </li> */}
          <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
          <li className="breadcrumb-item"><NavLink to="/dashboard/user/placements">Placement offers</NavLink></li>

          <li className="breadcrumb-item active" aria-current="page">{display}</li>
        </ol>
      </nav>
    );
  };

  const nomargin = {

    padding: "0",
    marginBottom: "0.2rem"
  }


  const CompanyCard = ({ props }) => {
    return (
      <>
        <div className="card" style={{ width: "14rem", minHeight: "40vh" }}>
          <div className="text-center">
            <img className='text-center' src={props.imglink} alt={props.name} style={{ width: "10rem", height: "20vh", border: "solid 1px black", padding: "1px" }} />
          </div>
          <h3 className='text-center my-2'>{props.name}</h3>
          <p className='text-center' style={nomargin}>{props.avpkg} LPA</p>
          <div className={styles.row} style={{ margin: "0", padding: "0" }}>
            <p className="text-center" style={nomargin}> <i class="fa-solid fa-location-dot px-1 mx-1"></i>{props.location}</p>

            <p style={nomargin}> <i class="fa-solid fa-user"></i> {props.selected} offers</p>
          </div>
          <p style={nomargin}>Since {props.startBatch} - Hired {props.times} times</p>
          <p style={nomargin}>{props.des}</p>
        </div>
      </>
    )
  }



  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const AllCompaniesList = () => {

    const [companies, setCompanies] = useState([]);

    useEffect(() => {
      const fetchCompanies = async () => {
        try {
          const querySnapshot = await getDocs(collection(db, "/becstuoweb/navigation/placements/placement offers/AllCompanies"));
          const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setCompanies(data);
          setLoading(false)
        } catch (error) {
          console.error("Error fetching companies: ", error);
        }
      };

      fetchCompanies();
    }, []);

    setLoading(false)

    return (
      <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", gap: "20px", padding: "20px" }}>
        {companies.map(company => (
          <>
            <CompanyCard props={company} />

          </>
        ))}
      </div>
    );
  };



  return (
    <>
      <Nav />
      <Breadcrumb />

      <div className={styles.main}>
        <div className={styles.left}>
          {r20links.map((link) => (
            <>
              <div>
                <button className='btn btn-sm btn-primary m-1 p-2' onClick={(e) => { setDisplay(link.title) }} style={{ width: "95%" }}>{link.title}</button>
              </div>
            </>
          ))}
        </div>
        <div className={styles.right}>
          <h2 className="text-center">All companies</h2>

          {display === "" ? <AllCompaniesList /> : ""}
          {loading ? <Spinner /> : ""}
        </div>
      </div>

      <Footer />
    </>
  )
}

export default PlacementOffers