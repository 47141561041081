import React from 'react';
import { Carousel } from 'antd';
import styles from "../css/Homepageservices.module.css"


const HomepageServices = () => {
    const carouselImages = [

        "https://shorturl.at/mEFY0",
        "https://images.shiksha.com/mediadata/images/articles/1581333516phpVSycrt.jpeg",
        "https://st2.depositphotos.com/3837271/8401/i/950/depositphotos_84015096-stock-photo-hostels-written-in-search-bar.jpg",
        "https://images.shiksha.com/mediadata/images/articles/1591428914phpMABeQI.jpeg",
        "https://cdn.vectorstock.com/i/1000x1000/94/12/rooms-icon-hotel-symbol-flat-vector-6759412.webp"

    ];

    return (

        <Carousel autoplay>
            {carouselImages.map(image => (
                <img src={image} className={styles.cardimage} alt="imager" style={{ width: "80%", height: "300px", border: "solid 1px black" }} />
            ))}
        </Carousel>

    );
}

export { HomepageServices };
