import React, { useState, useEffect } from 'react';
import { firebaseConfig, Footer, Nav, SmallSpinner, Spinner } from '../../context/imports';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { useNavigate } from 'react-router-dom'; // For navigation
import styles from './Articles.module.css';
import { ArticleCard } from './ArticleCard';




const Articles = () => {
    const [articles, setArticles] = useState([]);
    const navigate = useNavigate(); // For navigation to the article page
    const [loading, setLoading] = useState(false)

    // Fetch Articles from Firebase
    const fetchArticles = async () => {
        setLoading(true)
        try {
            const app = initializeApp(firebaseConfig);
            const db = getFirestore(app);
            const articlesSnapshot = await getDocs(collection(db, '/becstuoweb/navigation/articles'));
            const articlesList = articlesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setArticles(articlesList);
            setLoading(false)
        } catch (error) {
            console.error('Error fetching articles:', error);
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchArticles();
    }, []);

    // Article Card Component
   
    return (
        <>
            <Nav />
            <div className={styles.container}>
                <h1 className={styles.header}>Articles</h1>
                <div className={styles.row}>
                    {loading ? (
                        <Spinner />
                    ) : articles.length > 0 ? (
                        articles.map((article) => (
                            <ArticleCard key={article.id} article={article} />
                        ))
                    ) : (
                        <p className={styles.noArticles}>No articles available at the moment.</p>
                    )}
                </div>

            </div>
            <Footer />
        </>
    );
};




export default Articles;
