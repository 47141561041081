import React, { useContext } from "react";
import Footer from "../../../../components/Reusable/js/Footer";
import AuthContext from "../../../../context/AuthContext";
import AdminMenu from "../../../../components/admin/js/AdminMenu";
import Nav from "../../../../components/Reusable/js/Nav";
import { FaUser, FaEnvelope, FaPhone, FaUniversity, FaShieldAlt } from "react-icons/fa"; // Import necessary FontAwesome icons



const Profile = () => {
    const [auth] = useContext(AuthContext);
    const isadmin = auth?.user?.role === "0";

    const Profilecard = () => {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}>
                <div className="card shadow-sm" style={{ width: "30%", borderRadius: "10px", padding: "20px" }}>
                    <div className="card-body">
                        <h2 className="text-center mb-4" style={{ fontWeight: "bold" }}>
                            <FaUser className="me-2" /> Profile
                        </h2>
                        <hr />

                        <div className="d-flex justify-content-center">
                            <div className="profile-info" style={{ width: "70%" }}>
                                <p className="profile-detail">
                                    <FaUser className="me-2 text-primary" />
                                    <strong>Name:</strong> {auth?.user.name}
                                </p>
                                <p className="profile-detail">
                                    <FaUniversity className="me-2 text-warning" />
                                    <strong>Reg No:</strong> {auth?.user.regno}
                                </p>
                                <p className="profile-detail">
                                    <FaEnvelope className="me-2 text-danger" />
                                    <strong>Email:</strong> {auth?.user.email}
                                </p>
                                <p className="profile-detail">
                                    <FaPhone className="me-2 text-success" />
                                    <strong>Phone:</strong> {auth?.user.phone}
                                </p>
                                <p className="profile-detail">
                                    <FaShieldAlt className="me-2 text-info" />
                                    <strong>Security Answer:</strong> {auth?.user.answer}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

return (
    <>
        <Nav />

        {
            isadmin ? <div className="container mt-4">
                <div className="row">
                    <div className="col-lg-3">
                        {isadmin && <AdminMenu />}
                    </div>

                    <div className="col-lg-9">
                        <div className="card shadow-sm" style={{ borderRadius: "10px", padding: "20px" }}>
                            <div className="card-body">
                                <h2 className="text-center mb-4" style={{ fontWeight: "bold" }}>
                                    <FaUser className="me-2" /> Profile
                                </h2>
                                <hr />

                                <div className="d-flex justify-content-center">
                                    <div className="profile-info" style={{ width: "70%" }}>
                                        <p className="profile-detail">
                                            <FaUser className="me-2 text-primary" />
                                            <strong>Name:</strong> {auth?.user.name}
                                        </p>
                                        <p className="profile-detail">
                                            <FaUniversity className="me-2 text-warning" />
                                            <strong>Reg No:</strong> {auth?.user.regno}
                                        </p>
                                        <p className="profile-detail">
                                            <FaEnvelope className="me-2 text-danger" />
                                            <strong>Email:</strong> {auth?.user.email}
                                        </p>
                                        <p className="profile-detail">
                                            <FaPhone className="me-2 text-success" />
                                            <strong>Phone:</strong> {auth?.user.phone}
                                        </p>
                                        <p className="profile-detail">
                                            <FaShieldAlt className="me-2 text-info" />
                                            <strong>Security Answer:</strong> {auth?.user.answer}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> :
                <Profilecard />
        }



        <Footer />
    </>
);
};

export default Profile;



// <div className={styles.profilePage}>
//       {/* Sidebar below navbar */}
//       <div className={styles.sidebar}>
//         <h3>Profile Menu</h3>
//         <ul>
//           <li 
//             className={activeSection === 'academic' ? styles.active : ''}
//             onClick={() => setActiveSection('academic')}>
//             Academic Details
//           </li>
//           <li 
//             className={activeSection === 'certifications' ? styles.active : ''}
//             onClick={() => setActiveSection('certifications')}>
//             Certifications
//           </li>
//           <li 
//             className={activeSection === 'contact' ? styles.active : ''}
//             onClick={() => setActiveSection('contact')}>
//             Contact Details
//           </li>
//         </ul>
//       </div>
      
//       {/* Main Content Area */}
//       <div className={styles.content}>
//         {activeSection === 'academic' && (
//           <div className="card shadow-sm">
//             <div className="card-body">
//               <h4>Academic Details</h4>
//               <p><strong>Department:</strong> {studentData.department}</p>
//               <p><strong>Year:</strong> {studentData.year}</p>
//               <p><strong>Roll Number:</strong> {studentData.rollNumber}</p>
//               <h5>Courses:</h5>
//               <ul>
//                 {studentData.academicDetails.courses.map((course, index) => (
//                   <li key={index}>{course}</li>
//                 ))}
//               </ul>
//               <h5>Achievements:</h5>
//               <ul>
//                 {studentData.academicDetails.achievements.map((achievement, index) => (
//                   <li key={index}>{achievement}</li>
//                 ))}
//               </ul>
//             </div>
//           </div>
//         )}

//         {activeSection === 'certifications' && (
//           <div className="card shadow-sm">
//             <div className="card-body">
//               <h4>Certifications</h4>
//               <ul>
//                 {studentData.certifications.map((cert, index) => (
//                   <li key={index}>{cert}</li>
//                 ))}
//               </ul>
//             </div>
//           </div>
//         )}

//         {activeSection === 'contact' && (
//           <div className="card shadow-sm">
//             <div className="card-body">
//               <h4>Contact Details</h4>
//               <p><strong>Phone:</strong> {studentData.contactDetails.phone}</p>
//               <p><strong>Address:</strong> {studentData.contactDetails.address}</p>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>