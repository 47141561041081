import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { firebaseConfig, Footer, Nav, Spinner } from '../../context/imports';
import { collection, query, where, getDocs, getFirestore } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import styles from './SingleArticle.module.css';

const SingleArticle = () => {
    const { slug } = useParams(); // Get slug from URL
    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(false)

    // Fetch article based on slug
    const fetchArticle = async () => {
        setLoading(true)
        try {
            const app = initializeApp(firebaseConfig);
            const db = getFirestore(app);
            const articlesRef = collection(db, '/becstuoweb/navigation/articles');
            const q = query(articlesRef, where('slug', '==', slug)); // Query based on the slug
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const articleData = querySnapshot.docs[0].data(); // Get the first match
                setArticle({ id: querySnapshot.docs[0].id, ...articleData });
            } else {
                console.error('No article found with this title');
            }
            setLoading(false)
        } catch (error) {
            console.error('Error fetching article:', error);
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchArticle();
    }, [slug]);

    // Render article content
    return (
        <>
            <Nav />
            <div className={styles.container}>
                {article ? (
                    <div className={styles.articleContainer}>
                        <h1 className={styles.title}>{article.title}</h1>
                        <p className={styles.date}>
                            {new Date(article.date).toLocaleDateString()} at{' '}
                            {new Date(article.time.seconds * 1000).toLocaleTimeString()}
                        </p>
                        <div className={styles.content}>
                            <p>{article.content}</p>
                        </div>

                        <div className={styles.links}>
                            <h3>Related Links</h3>
                            {article.links && article.links.map((link, index) => (
                                <a
                                    key={index}
                                    href={link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={styles.link}
                                >
                                    {link}
                                </a>
                            ))}
                        </div>

                        <div className={styles.tags}>
                            <h3>Tags</h3>
                            {article.tags && article.tags.map((tag, index) => (
                                <span key={index} className={styles.tag}>
                                    {tag}
                                </span>
                            ))}
                        </div>
                    </div>
                ) : (
                    <p>Loading... <Spinner/></p>
                )}
            </div>
            <Footer />
        </>
    );
};

export default SingleArticle;
