import React, { useState, useEffect } from 'react';
import { firebaseConfig, Footer, Nav, SmallSpinner, Spinner } from '../../context/imports';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { useNavigate } from 'react-router-dom'; // For navigation
import styles from './Articles.module.css';

export const ArticleCard = ({ article }) => {

    const navigate=useNavigate()

    return (
        <div className={styles.articleCard}>
            <h2 className={styles.articleTitle}>{article.title}</h2>
            <p className={styles.articleSnippet}>
                {article.content.slice(0, 100)}... {/* Short snippet */}
            </p>
            <button
                className='btn btn-sm btn-primary'
                onClick={() => navigate(`/blog/articles/${article.slug}`)}
            >
                Read More
            </button>
        </div>
    );
};